/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import {
  GuideArticle,
  StyledHeroGuide,
  StyledHeadline,
} from '../components/blog/StyledBlogArticle'
import SEO from '../components/Seo'

const trackButtonClick = (slugForDownload) => {
  if (typeof window !== 'undefined') {
    // Parse the URL to get UTM parameters
    const urlParams = new URLSearchParams(window.location.search)
    const utmSource = urlParams.get('utm_source')
    const utmMedium = urlParams.get('utm_medium')
    const utmCampaign = urlParams.get('utm_campaign')

    window.gtag('event', 'guide_button_click', {
      event_category: 'guide',
      event_label: 'Guide Button Click',
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
    })

    // Construct the download URL with UTM parameters
    let downloadUrl = `/download/${slugForDownload}`
    if (utmSource || utmMedium || utmCampaign) {
      downloadUrl += `?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`
    }

    // Navigate to the download URL
    window.location.href = downloadUrl
  }
}

const StyledImage = styled.div`
  max-width: 920px;
  min-height: 460px;
  margin: -120px auto 100px;
  height: fit-content;

  div {
    border-radius: 10px;
    box-shadow: 0 23px 55px rgb(0 0 0 / 34%);
  }
`
const ButtonWrapper = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 60px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 54px 16px;
`
const ButtonWithBackgroundWrapper = styled.div`
  display: flex;
  background: url(${({ buttonBackground }) => buttonBackground}) no-repeat;
  background-size: 100% 100%;
  height: 292px;
  width: 102%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  position: relative;
  right: 15px;
`
const Button = styled.div`
  color: #fff;
  text-align: center;
  background-color: #5a55ab;
  padding: 10px 30px;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 37.714px;
  letter-spacing: 0.192px;
  border: 2px solid #5a55ab;
  border-radius: 100px;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  position: relative;
  box-shadow: 0 25px 30px -10px rgba(90, 85, 171, 0.6);
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  text-decoration: none !important;

  &:hover {
    color: #5a55ab;
    background-color: #fff;
    border-color: #5a55ab;
  }
`
const ButtonFirstText = styled.span`
  color: #5a55ab;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  text-transform: uppercase;
`
const ButtonSecondText = styled.span`
  color: #3b4b66;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  padding: 12px 0px 48px;
  text-align: center;
`
const ButtonWithBackground = styled.div`
  color: #5a55ab;
  text-align: center;
  background-color: #fff;
  padding: 10px 30px;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 20px;
  border: 2px solid #fff;
  border-radius: 100px;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
  text-decoration: none !important;
  span {
    padding-left: 5px;
  }
  &:hover {
    color: #fff;
    background-color: #5a55ab;
    border-color: #fff;
    span svg path {
      stroke: #fff;
    }
  }
`
const ButtonWithBackgroundFirstText = styled.span`
  color: #fff;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
`
const AuthorsSmall = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`
const AuthorSmall = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  div {
    text-align: left;
  }
`
const AuthorImage = styled.div`
  border-radius: 50%;
  width: 42px;
  height: 42px;
`

const AuthorSmallName = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
`
const AutorSmallDate = styled.div`
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
`
const AuthorBigName = styled.div`
  color: #152540;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
`
const AutorBigDate = styled.div`
  color: #8494b2;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
`
const AuthorBigDescription = styled.div`
  color: #556685;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 6px 0;
`
const AuthorBig = styled.div`
  border-radius: 10px;
  background: #f6f8fc;
  display: flex;
  align-items: flex-start;
  padding: 24px;
  gap: 14px;
  margin-bottom: 18px;
`
const FindMoreSection = styled.div`
  border-top: 1px solid #d9e1ee;
  padding: 50px 0px 50px;
`
const TypeAndTime = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
  a {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
`
const Dot = styled.div`
  background: #fff;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin-inline: 10px 4px;
`
const BackgroundButton = (
  firstText,
  buttonBackground,
  buttonText,
  slugForDownload
) => (
  <ButtonWithBackgroundWrapper buttonBackground={buttonBackground}>
    <ButtonWithBackgroundFirstText>{firstText}</ButtonWithBackgroundFirstText>
    <ButtonWithBackground onClick={() => trackButtonClick(slugForDownload)}>
      {buttonText}
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
        >
          <path
            d="M11.4997 1.75024L1.5 11.2068"
            stroke="#5A55AB"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.4999 10.9885V1.75024H1.73389"
            stroke="#5A55AB"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </ButtonWithBackground>
  </ButtonWithBackgroundWrapper>
)
const NoBackgroundButton = (
  firstText,
  secondText,
  buttonText,
  slugForDownload
) => (
  <ButtonWrapper>
    <ButtonFirstText>{firstText}</ButtonFirstText>
    <ButtonSecondText>{secondText}</ButtonSecondText>
    <Button onClick={() => trackButtonClick(slugForDownload)}>
      {buttonText}{' '}
    </Button>
  </ButtonWrapper>
)
const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData } = node.data.target
      if (!gatsbyImageData) {
        // asset is not an image
        return null
      }

      return (
        <GatsbyImage
          image={node.data.target.gatsbyImageData}
          style={{ height: 'min-content' }}
        />
      )
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      const {
        buttonBackground,
        buttonText,
        firstText,
        isColumn,
        secondText,
        slugForDownload,
      } = node.data.target

      if (isColumn)
        return NoBackgroundButton(
          firstText,
          secondText,
          buttonText,
          slugForDownload
        )
      return BackgroundButton(
        secondText,
        buttonBackground.file.url,
        buttonText,
        slugForDownload
      )
    },
    [INLINES.HYPERLINK]: (node) => (
      <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
        {node.content[0].value}
      </a>
    ),
  },
}
function GuidePage({ pageContext: { data } }) {
  const pageContent = renderRichText(data.content, options)
  const pageExcerpt = renderRichText(data.excerpt)

  return (
    <>
      <SEO
        title={data.metadata?.title || data.title}
        description={
          data.metadata?.description || pageExcerpt[0].props.children[0]
        }
        imageUrl={`https:${data.heroImage.file.url}`}
        url={`https://innential.com/blog/${data.slug}/`}
      />

      <StyledHeroGuide>
        <TypeAndTime>
          {data.type}
          <Dot />
          {data.timeRequired}
        </TypeAndTime>
        <StyledHeadline>{data.title}</StyledHeadline>
        <AuthorsSmall>
          <AuthorSmall>
            <AuthorImage>
              <GatsbyImage
                image={data.authorPicture?.gatsbyImageData}
                alt=" "
                style={{ borderRadius: '50%' }}
              />
            </AuthorImage>
            <div>
              <AuthorSmallName>{data.author}</AuthorSmallName>
              <AutorSmallDate>{data.publishedTime}</AutorSmallDate>
            </div>
          </AuthorSmall>
          <AuthorSmall>
            <AuthorImage>
              <GatsbyImage
                image={data.author1Picture?.gatsbyImageData}
                alt=" "
                style={{ borderRadius: '50%' }}
              />
            </AuthorImage>
            <div>
              <AuthorSmallName>{data.author1}</AuthorSmallName>
              <AutorSmallDate>{data.publishedTime}</AutorSmallDate>
            </div>
          </AuthorSmall>
          <AuthorSmall>
            <AuthorImage>
              <GatsbyImage
                image={data.author2Picture?.gatsbyImageData}
                alt=" "
                style={{ borderRadius: '50%' }}
              />
            </AuthorImage>
            <div>
              <AuthorSmallName>{data.author2}</AuthorSmallName>
              <AutorSmallDate>{data.publishedTime}</AutorSmallDate>
            </div>
          </AuthorSmall>
        </AuthorsSmall>
      </StyledHeroGuide>
      <StyledImage>
        <GatsbyImage image={data.heroImage?.gatsbyImageData} alt="hero img" />
      </StyledImage>
      <GuideArticle
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <section itemProp="articleBody">{pageContent}</section>
        <FindMoreSection>
          Find more learning paths and useful learning material directly at{' '}
          {` `}
          <Link to="/learning">Innential</Link>.
        </FindMoreSection>
        <AuthorBig>
          <AuthorImage>
            <GatsbyImage
              image={data.authorPicture?.gatsbyImageData}
              alt="Author"
              style={{ width: '42px', height: '42px', borderRadius: '50%' }}
            />
          </AuthorImage>
          <div>
            <AuthorBigName>{data.author}</AuthorBigName>
            <AuthorBigDescription>{data.authorInfo}</AuthorBigDescription>
            <AutorBigDate>{data.publishedTime}</AutorBigDate>
          </div>
        </AuthorBig>
        <AuthorBig>
          <AuthorImage>
            <GatsbyImage
              image={data.author1Picture?.gatsbyImageData}
              alt="Author"
              style={{ width: '42px', height: '42px', borderRadius: '50%' }}
            />
          </AuthorImage>
          <div>
            <AuthorBigName>{data.author1}</AuthorBigName>
            <AuthorBigDescription>{data.author1Info}</AuthorBigDescription>
            <AutorBigDate>{data.publishedTime}</AutorBigDate>
          </div>
        </AuthorBig>
        <AuthorBig>
          <AuthorImage>
            <GatsbyImage
              image={data.author2Picture?.gatsbyImageData}
              alt="Author"
              style={{ width: '42px', height: '42px', borderRadius: '50%' }}
            />
          </AuthorImage>
          <div>
            <AuthorBigName>{data.author2}</AuthorBigName>
            <AuthorBigDescription>{data.author2Info}</AuthorBigDescription>
            <AutorBigDate>{data.publishedTime}</AutorBigDate>
          </div>
        </AuthorBig>
      </GuideArticle>
    </>
  )
}

export default GuidePage
